import React from 'react';
import { withRouter } from 'react-router-dom';
import { showDate, showOccupancy, getTableImg, getAssociation, sortDates } from '../../services/common.helper';

export class KurseTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollTop: 0
        };
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(document.location.search.substring(1));
        const scrollY = searchParams.get("STop");
        setTimeout(() => {
            window.scrollTo(0, scrollY);
        }, 500);
    }
    
    handleBookmarkCourse = (e, courseNumber) => {
        e.stopPropagation();
        this.props.onBookmarkCourse(courseNumber)
    }

    handleDeleteBookmarkedCourses = () => {
        this.props.onDeleteBookmarkedCourses();
        this.props.onShowAllCourses();
    }

    handleSelectCourse = (courseNumber) => {
        const searchParams = new URLSearchParams(document.location.search.substring(1));
        searchParams.set("STop", window.scrollY);
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
        this.props.history.push(`/kurse/${courseNumber}`);
    }

    render() {
        return (
            <div id="course-table">
                <div className="row mt-small mb-medium course-table-desktop">
                    <div className="col">
                        <table className="table course-table" id="course-table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: '10%' }}>Kursnummer</th>
                                    <th scope="col" style={{ width: '28%' }}>Kurs</th>
                                    <th scope="col" style={{ width: '10%' }}>Datum</th>
                                    <th scope="col" style={{ width: '18%' }}>Ort</th>
                                    <th scope="col" style={{ width: '7%' }}></th>
                                    <th scope="col" style={{ width: '2%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.showOnlyBookmarkedCourses && (this.props.bookmarkedCourses || []).map(course => (
                                        <tr onClick={ () => this.handleSelectCourse(course.number) } key={ course.number }>
                                            <td style={{ width: '10%' }}>{ course.number } <span style={{ marginLeft: '8px' }}>{ getAssociation(course.number) }</span></td>
                                            <td className="course-title" style={{ width: '28%' }}>
                                                <span>{ course.name }</span>
                                                <br/>
                                                <p className="table-course-description">{ course.description.length > 100 ? course.description.substr(0, 100) + ' ...' : course.description }</p>
                                                <p className="table-course-occupancy">{ !course.future ? 'Die Maßnahme hat bereits begonnen, eine Anmeldung ist nicht mehr möglich' : showOccupancy(course) }</p>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                            {
                                                showDate(course.dates) && 
                                                <p>{ sortDates(course.dates || []).map((date) => date.date).join(', ') }</p>
                                            }
                                            </td>
                                            <td style={{ width: '18%' }}>{ course.location }</td>
                                            <td style={{ width: '7%' }}>{ getTableImg(course) }</td>
                                            <td style={{ width: '2%', padding: '0.75rem 0.25rem' }}>
                                                <svg onClick={ (e) => this.handleBookmarkCourse(e, course) } fill="rgb(221,78,75)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                    { !this.props.bookmarkedCourses.find(c => c.number === course.number) ?
                                                    <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" class=""></path>
                                                    :
                                                    <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" class=""></path>
                                                    }
                                                </svg>
                                            </td>
                                        </tr>
                                    ))
                                }

                                {
                                    !this.props.showOnlyBookmarkedCourses && (this.props.courses || []).map(course => (
                                        <tr onClick={ () => this.handleSelectCourse(course.number) } key={ course.number }>
                                            <td style={{ width: '10%' }}>{ course.number } <span style={{ marginLeft: '8px' }}>{ getAssociation(course.number) }</span></td>
                                            <td className="course-title" style={{ width: '28%' }}>
                                                <span>{ course.name }</span>
                                                <br/>
                                                <p className="table-course-description">{ course.description.length > 100 ? course.description.substr(0, 100) + ' ...' : course.description }</p>
                                                <p className="table-course-occupancy">{ !course.future ? 'Die Maßnahme hat bereits begonnen, eine Anmeldung ist nicht mehr möglich' : showOccupancy(course) }</p>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                            {
                                                showDate(course.dates) && 
                                                <p>{ sortDates(course.dates || []).map((date) => date.date).join(', ') }</p>
                                            }
                                            </td>
                                            <td style={{ width: '18%' }}>{ course.location }</td>
                                            <td style={{ width: '7%' }}>{ getTableImg(course) }</td>
                                            <td style={{ width: '2%', padding: '0.75rem 0.25rem' }}>
                                            { !this.props.disableBookmarks &&
                                                <svg onClick={ (e) => this.handleBookmarkCourse(e, course) } fill="rgb(221,78,75)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                    { !this.props.bookmarkedCourses.find(c => c.number === course.number) ?
                                                    <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" class=""></path>
                                                    :
                                                    <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" class=""></path>
                                                    }
                                                </svg>
                                            }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row mt-small mb-medium course-table-mobile">
                    <div className="col-md-12">
                        <div className="course-table-mobile-header">
                            <p>Kurs</p>
                        </div>
                    </div>
                    {
                        this.props.showOnlyBookmarkedCourses && (this.props.bookmarkedCourses || []).map(course => (
                            <div className="col-md-12" key={ course.number } onClick={ () => this.handleSelectCourse(course.number) }>
                                <div className="course-inner">
                                    <svg onClick={ (e) => this.handleBookmarkCourse(e, course) } style={{ width: '20px', position: 'absolute', top: '10px', right: '10px' }} fill="rgb(221,78,75)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        { !this.props.bookmarkedCourses.find(c => c.number === course.number) ?
                                        <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" class=""></path>
                                        :
                                        <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" class=""></path>
                                        }
                                    </svg>
                                    <p>{ course.number }<span style={{ marginLeft: '8px' }}>{ getAssociation(course.number) }</span></p>
                                    <p className="course-title"><span>{ course.name }</span></p>
                                    <div className="row">
                                        <div className="col-12 course-inner-infos">
                                        {
                                            showDate(course.dates) && 
                                            <p>{ sortDates(course.dates || []).map((date) => date.date).join(', ') }</p>
                                        }

                                            <p>{ course.location }</p>
                                        </div>
                                    </div>
                                    <p>{ !course.future ? 'Die Maßnahme hat bereits begonnen, eine Anmeldung ist nicht mehr möglich' : showOccupancy(course) }</p>
                                    { getTableImg(course) }
                                </div>
                            </div>
                        ))
                    }

                    {
                        !this.props.showOnlyBookmarkedCourses && (this.props.courses || []).map(course => (
                            <div className="col-md-12" key={ course.number } onClick={ () => this.handleSelectCourse(course.number) }>
                                <div className="course-inner">
                                { !this.props.disableBookmarks &&
                                    <svg onClick={ (e) => this.handleBookmarkCourse(e, course) } style={{ width: '20px', position: 'absolute', top: '10px', right: '10px' }} fill="rgb(221,78,75)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        { !this.props.bookmarkedCourses.find(c => c.number === course.number) ?
                                        <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" class=""></path>
                                        :
                                        <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" class=""></path>
                                        }
                                    </svg>
                                }
                                    <p>{ course.number }<span style={{ marginLeft: '8px' }}>{ getAssociation(course.number) }</span></p>
                                    <p className="course-title"><span>{ course.name }</span></p>
                                    <div className="row">
                                        <div className="col-12 course-inner-infos">
                                        {
                                            showDate(course.dates) && 
                                            <p>{ sortDates(course.dates || []).map((date) => date.date).join(', ') }</p>
                                        }

                                            <p>{ course.location }</p>
                                        </div>
                                    </div>
                                    <p>{ !course.future ? 'Die Maßnahme hat bereits begonnen, eine Anmeldung ist nicht mehr möglich' : showOccupancy(course) }</p>
                                    { getTableImg(course) }
                                </div>
                            </div>
                        ))
                    }
                </div>

            {
            this.props.showOnlyBookmarkedCourses && !Boolean(this.props.bookmarkedCourses.length) && 
                <div className="row mb-large">
                    <div className="col align-center">
                        <p>Es wurde noch kein Kurs zu den Favoriten hinzugefügt.</p> 
                    </div>
                </div>  
            }
            
            {
            this.props.showOnlyBookmarkedCourses && Boolean(this.props.bookmarkedCourses.length) && 
                <div className="row mb-large">
                    <div className="col align-center">
                        <p onClick={ this.handleDeleteBookmarkedCourses } className="load-more-button cursor-pointer">
                            <i className="fa fa-trash"></i>
                            Favoriten löschen
                        </p>
                    </div>
                </div>  
            }

            {/*
            !this.props.showOnlyBookmarkedCourses && this.props.onLoadMore && this.props.showLoadMore && 
                <div className="row mb-large">
                    <div className="col align-center">
                        <p onClick={ this.props.onLoadMore } className="load-more-button cursor-pointer">
                            <i className="fa fa-angle-right"></i>
                            mehr laden
                        </p> 
                    </div>
                </div>  
            */}

            </div>
        );
    }
}

export default withRouter(KurseTable);