import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { filter, advancedFilter } from '../../services/filters';
import Filter from '../Filter/Filter';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";

registerLocale("de", de);

export class SearchAndFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      advancedFilterCollapsed: true
    };
  }

  handleSearchChange = (ev) => {
    this.props.onUrlParamsChange('Suche', ev.target.value);
    this.props.onUrlParamsChange('Sort', 'Relevanz');
    if (!ev.target.value) {
      this.props.onUrlParamsChange('Sort', 'Lehrgangsdatum');
    }
  }

  handleFilterChange = (key) => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const filterParam = params.get("Filter");
    let currentFilter;
    let nextFilter;
    try {
      currentFilter = filterParam.split('-');
    } catch {
      currentFilter = [];
    }

    if (!currentFilter.includes(key)) {
      nextFilter = currentFilter.concat(key);
    }
    else {
      const filterIndex = currentFilter.findIndex((filter) => filter === key);
      nextFilter = [ ...currentFilter ];
      nextFilter.splice(filterIndex, 1);
    }
    this.props.onUrlParamsChange('Filter', nextFilter.join('-'));
  }

  handleSortChange = (ev) => {
    this.props.onUrlParamsChange('Sort', ev.target.value);
  }

  handleDateFilterChange = (date) => {
    const formatedDate = moment(date).format('DD-MM-YYYY');
    this.props.onUrlParamsChange('Date', formatedDate);
    if (date) {
      this.props.onUrlParamsChange('RegistrationPeriodActive', '0');
    }
  }

  toggleAdvancedFilter = () => {
    const currentAdvancedFilterCollapsed = this.state.advancedFilterCollapsed;
    this.setState({ advancedFilterCollapsed: !currentAdvancedFilterCollapsed });
  }

  resetFilter = () => {
    this.props.onUrlParamsChange('Filter', '');
    this.props.onUrlParamsChange('Suche', '');
    this.props.onUrlParamsChange('Date', '');
    this.props.onUrlParamsChange('Sort', 'Lehrgangsdatum');
  }

  resetDateFilter = () => {
    this.props.onUrlParamsChange('Date', '');
  }

  handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.jumpToTable();
    }
  }

  jumpToTable = () => {
    const el = document.getElementById("course-table");
    el.scrollIntoView(); 
  }

  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get("Suche");
    let filterParam = searchParams.get("Filter");
    const associationParam = searchParams.get("Verband");
    const sortParam = searchParams.get("Sort");
    let initSort = sortParam ? sortParam : 'Lehrgangsdatum';
    const dateFilterParam = searchParams.get("Date");
    const initDateFilter = dateFilterParam ? moment(dateFilterParam, "DD-MM-YYYY").toDate() : null;
    const registrationPeriodActiveParam = searchParams.get("RegistrationPeriodActive");
    let currentFilter;
    try {
      if (filterParam) {
        currentFilter = filterParam.split('-');
      }
      else {
        currentFilter = [];
      }      
    } catch {
      currentFilter = [];
    }

    return (
      <div>
        {/*
        <div className="row row-find-headline mb-medium schallwelle-row">
          <img src={SchallwelleImage} className="schallwelle" alt="Nordbayerischer Musikbund e.V. Kursfinder" />
          <div className="col align-center">
            <h1>Übersicht Kurse / Fortbildungen / Angebote</h1>
          </div>
        </div>
        */}

        <div className="row search-row desktop" style={{ marginBottom: '10px' }}>
          <div className="col align-center">
            <div className="input-group" id="search-field">
              <input 
                type="search" 
                className="form-control"
                placeholder="Suche nach Stichwort" 
                value={ searchParam } 
                onChange={this.handleSearchChange}
                onKeyUp={ this.handleSearchKeyUp }
              />
              <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={ this.jumpToTable }>
                <div className="input-group-text"><i className="fa navigation-icon fa-search"></i></div>
              </div>
              <DatePicker 
                locale="de"
                placeholderText="Suche nach Datum" 
                dateFormat="dd.MM.yyyy"
                className="form-control" 
                selected={ initDateFilter } 
                onChange={ this.handleDateFilterChange }
                calendarStartDay={1}
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
              {initDateFilter &&
              <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={ this.resetDateFilter }>
                <div className="input-group-text"><i className="fa navigation-icon fa-close"></i></div>
              </div>
              }
              <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={ this.jumpToTable }>
                <div className="input-group-text"><i className="fa navigation-icon fa-search"></i></div>
              </div>
              <a href="#course-table" className="btn submit-button reset-button treffer-button">{ this.props.coursesCount } Treffer</a>
            </div>
          </div>
        </div>

        <div className="row search-row mobile" style={{ marginBottom: '10px' }}>
          <div className="col align-center" style={{ marginBottom: '10px' }}>
            <div className="input-group" id="search-field">
              <input 
                type="search" 
                className="form-control"
                placeholder="Suche nach Stichwort" 
                value={ searchParam } 
                onChange={this.handleSearchChange}
                onKeyUp={ this.handleSearchKeyUp }
              />
              <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={ this.jumpToTable }>
                <div className="input-group-text"><i className="fa navigation-icon fa-search"></i></div>
              </div>
            </div>
          </div>
          <div className="col align-center">
            <div id="search-field">
              <DatePicker 
                locale="de"
                placeholderText="Suche nach Datum" 
                dateFormat="dd.MM.yyyy"
                className="form-control" 
                selected={ initDateFilter } 
                onChange={ this.handleDateFilterChange }
                calendarStartDay={1}  
                onChangeRaw={(e) => e.preventDefault()}
              />
              <a href="#course-table" className="btn submit-button reset-button treffer-button">{ this.props.coursesCount } Treffer</a>
            </div>
          </div>
        </div>

        <div className="row filter-row filter-row-desktop" style={{ marginBottom: '10px' }}>
          <div className="col align-center">
            <button type="button" className="btn submit-button reset-button" style={{ textTransform: 'none', marginRight: '10px' }} onClick={ () => this.setState({ showFilter: !this.state.showFilter }) }>
              { this.state.showFilter ? 'Filter ausblenden' : 'Filter anzeigen' }
            </button>
            <button type="button" className="btn submit-button reset-button" style={{ textTransform: 'none'}} onClick={ this.resetFilter } disabled={ !currentFilter.length && !searchParam && !dateFilterParam }>Filter und Suche zurücksetzen</button>
          </div>
        </div>

        <div className="row filter-row filter-row-mobile">
          <div className="col-7">
            <button type="button" className="btn submit-button reset-button mobile-button" onClick={ this.resetFilter } disabled={ !currentFilter.length && !searchParam && !dateFilterParam }>Filter/Suche zurücksetzen</button>
          </div>
          <div className="col-5">
            <button type="button" className="btn submit-button reset-button mobile-button" onClick={ () => this.setState({ showFilter: !this.state.showFilter }) }>
              { this.state.showFilter ? 'Filter ausblenden' : 'Filter anzeigen' }
            </button>
          </div>
        </div>

      { this.state.showFilter &&
        <div className="row row-filter">
          <div className="col-md-2"></div>
          {
            advancedFilter.map((filter) => (
              <Filter 
                key={filter.id} 
                data={filter} 
                onFilterChange={this.handleFilterChange}
                activeFilter={ currentFilter }
              />
            ))
          }
        </div>
      }

        <div className="row filter-row filter-row-desktop" style={{ marginBottom: '10px', marginLeft: '0px', marginRight: '0px' }}>
          <div style={{ width: '20%', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${!associationParam || associationParam === '' ? 'active' : ''}` } style={{ height: '46.6px', padding: '0px' }} onClick={ () => this.props.onUrlParamsChange('Verband', '') }>Alle Verbände</button>
          </div>
          <div style={{ width: '20%', paddingLeft: '5px', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'L' ? 'active' : ''}` } style={{ height: '46.6px', padding: '0px' }} onClick={ () => this.props.onUrlParamsChange('Verband', 'L') }>Nordbayerischer Musikbund /<br/> Nordbayerische Bläserjugend</button>
          </div>
          <div style={{ width: '20%', paddingLeft: '5px', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'B' ? 'active' : ''}` } style={{ height: '46.6px', padding: '0px' }} onClick={ () => this.props.onUrlParamsChange('Verband', 'B') }>Blasmusikverband<br/>Vorspessart</button>
          </div>
          <div style={{ width: '20%', paddingLeft: '5px', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'M' ? 'active' : ''}` } style={{ height: '46.6px', padding: '0px' }} onClick={ () => this.props.onUrlParamsChange('Verband', 'M') }>Musikbund Ober- /<br/> Niederbayern</button>
          </div>
          <div style={{ width: '20%', paddingLeft: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'A' ? 'active' : ''}` } style={{ height: '46.6px', padding: '0px' }} onClick={ () => this.props.onUrlParamsChange('Verband', 'A') }>Allgäu-Schwäbischer<br/>Musikbund</button>
          </div>
        </div>

        <div className="row mb-medium filter-row filter-row-mobile">
          <div className="col-2" style={{ width: '20%', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${!associationParam || associationParam === '' ? 'active' : ''}` } style={{ textTransform: 'none'}} onClick={ () => this.props.onUrlParamsChange('Verband', '') }>Alle</button>
          </div>
          <div className="col-4" style={{ width: '20%', paddingLeft: '5px', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'L' ? 'active' : ''}` } style={{ textTransform: 'none'}} onClick={ () => this.props.onUrlParamsChange('Verband', 'L') }>NBMB/NBBJ</button>
          </div>
          <div className="col-2" style={{ width: '20%', paddingLeft: '5px', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'B' ? 'active' : ''}` } style={{ textTransform: 'none'}} onClick={ () => this.props.onUrlParamsChange('Verband', 'B') }>BVV</button>
          </div>
          <div className="col-2" style={{ width: '20%', paddingLeft: '5px', paddingRight: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'M' ? 'active' : ''}` } style={{ textTransform: 'none'}} onClick={ () => this.props.onUrlParamsChange('Verband', 'M') }>MON</button>
          </div>
          <div className="col-2" style={{ width: '20%', paddingLeft: '5px' }}>
            <button type="button" className={ `btn association-button reset-button ${associationParam === 'A' ? 'active' : ''}` } style={{ textTransform: 'none'}} onClick={ () => this.props.onUrlParamsChange('Verband', 'A') }>ASM</button>
          </div>
        </div>

        <div className="row" style={{ paddingTop: '10px' }}>
          <div className="col-md-4">
            <span>Sortieren nach: </span>
            <select class="form-select" onChange={ this.handleSortChange } value={ initSort }>
                {searchParam && <option value="Relevanz">Relevanz</option>}
                <option value="Lehrgangsdatum">Lehrgangsdatum</option>
                <option value="KursnummerAbsteigend">Kurs-Nummer absteigend</option>
                <option value="KursnummerAufsteigend">Kurs-Nummer aufsteigend</option>
            </select>
          </div>
          {/*
          <div className="col-md-2 course-limit-selector">
            <span>Kurse: </span>
            <select class="form-select" onChange={ (e) => this.props.onUrlParamsChange('Limit', e.target.value, true) } value={ this.props.selectedLimit }>
              { !['10', '25', '50'].includes(this.props.selectedLimit) &&
                <option value={ this.props.selectedLimit }>{ this.props.selectedLimit }</option>
              }
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
            </select>
          </div>
          */}
          <div className="col-md-4 show-favorites-container">
            <label style={{ cursor: 'pointer', marginRight: '4px' }} for="show-only-bookmarked-courses">
              <svg style={{ width: '20px', marginRight: '2px', position: 'relative', top: '-3px', cursor: 'pointer' }} fill="rgb(221,78,75)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" class=""></path>
              </svg>
              Favoriten
            </label>  
            <input 
              type="checkbox"
              id="show-only-bookmarked-courses" 
              style={{ cursor: 'pointer' }}
              checked={ this.props.showOnlyBookmarkedCourses }
              onClick={ () => this.props.onShowOnlyBookmarkedCourses(!this.props.showOnlyBookmarkedCourses) }
            />
          </div>
          <div className="col-md-4 registration-period-active-container">
            <label style={{ cursor: 'pointer', marginRight: '4px' }} for="registration-period-active" data-tip data-for='registration-period-tooltip'>
              Anmeldezeitraum aktiv
            </label>  
            <input 
              type="checkbox"
              id="registration-period-active" 
              checked={ registrationPeriodActiveParam === null || registrationPeriodActiveParam === '1' }
              onClick={ (e) => this.props.onUrlParamsChange('RegistrationPeriodActive', e.target.checked ? '1' : '0') }
              data-tip data-for='registration-period-tooltip'
            />
            <div className="registration-period-active-icon">
              <i className="fa fa-info-circle " data-tip data-for='registration-period-tooltip-mobile' data-event='click focus'></i>
            </div>
            <ReactTooltip id='registration-period-tooltip' className="registration-period-tooltip" textColor="inherit" backgroundColor='rgba(202, 221, 250, 1)' effect='solid'>
              <p>
                Kurse, deren Anmeldezeitraum abgelaufen ist,<br/>
                werden zunächst nicht angezeigt und können <br/>
                auch nicht über eine Stichwortsuche gefunden werden.<br/>
                Mit der Deaktivierung des Buttons werden noch<br/>
                nicht gestartete Kurse ergänzt, deren Anmeldezeitraum<br/>
                allerdings bereits abgelaufen ist.
              </p>
            </ReactTooltip>
            <ReactTooltip id='registration-period-tooltip-mobile' className="registration-period-tooltip mobile" globalEventOff='click' textColor="inherit" backgroundColor='rgba(202, 221, 250, 1)' effect='solid'>
              <p>
              Kurse, deren Anmeldezeitraum abgelaufen ist, werden zunächst nicht angezeigt und können auch nicht über eine Stichwortsuche gefunden werden. Mit der Deaktivierung des Buttons werden noch nicht gestartete Kurse ergänzt, deren Anmeldezeitraum allerdings bereits abgelaufen ist.
              </p>
            </ReactTooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;