import React from 'react';
import moment from 'moment';
import KurseTable from '../KurseTable/KurseTable';
import HttpService from '../../services/http.service';

export class LandingPageTargetGroups extends React.Component {
  httpService;
  courses;
  
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      coursesCount: 0
    };

    this.httpService = new HttpService();
  }

  componentDidMount() {
    let association = this.props.match.params.association;
    if (association && !['L','A','M','B'].includes(association)) {
      association = undefined;
    }
    let filter;
    try {
      filter = this.props.match.params.filter.split('-');
    } catch {
      filter = [];
    }
    this.httpService.getAllCourses()
      .then(response => {
        this.courses = response
          .filter(course => !association || association === 'Alle' || course.number[0] === association)
          .filter(course => this.compareTagsAndFilter(course, filter));
        this.pipeCourses();
      });
  }

  compareTagsAndFilter(course, filter) {
    if (!course.tags) {
      return false;
    }

    let show = false;
    filter.forEach(f => {
      if (course.tags.includes(f)) {
        show = true;
      }
    });
    return show;
  }

  handleSearchChange = (ev) => {
    this.setFilterSearchURLParams(ev.target.value);
    this.pipeCourses();
  }

  setFilterSearchURLParams = (search) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set("Suche", search);
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
  }

  resetSearch = () => {
    this.setFilterSearchURLParams('');
    this.pipeCourses();
  }

  pipeCourses() {
    this.sortCourses();
    this.filterCourses();
  }

  sortCourses() {
    const params = new URLSearchParams(document.location.search.substring(1));
    const sortParam = params.get("Sort");
    switch (sortParam) {
      case 'Lehrgangsdatum':
        this.courses = this.sortCoursesByDate();
        break;
      case 'KursnummerAufsteigend':
        this.courses = this.sortCoursesByCourseNumber();
        break;
      case 'KursnummerAbsteigend':
        this.courses = this.sortCoursesByCourseNumber(true);
        break;
      default:
        this.courses = this.sortCoursesByDate();
    }
  }

  sortCoursesByDate() {
    const nextCourses = [ ...this.courses ];
    const sortedCourses = nextCourses.map(course => {
      let date;
      try {
        const dateStr = course.dates[0].split('-')[0];
        date = moment(dateStr, 'DD.MM.YYYY').toDate();
      }
      catch {
        date = moment().add(5, 'y').toDate();
      };

      return {
        ...course,
        date
      };
    });
    sortedCourses.sort((a, b) => {
      return moment(a.date).toDate() - moment(b.date).toDate();
    });
    return sortedCourses;
  }

  sortCoursesByCourseNumber(desc) {
    const nextCourses = [ ...this.courses ];
    nextCourses.sort((a, b) => {
      if (desc) {
        return b.numberAsInt - a.numberAsInt;
      }
      return a.numberAsInt - b.numberAsInt;
    });
    return nextCourses;
  }

  filterCourses() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let searchParam = params.get("Suche");
    let courses = [ ...this.courses ];

    if (searchParam && searchParam.length >= 2) {
      courses = this.courses.filter(course => this.searchInCourse(course, searchParam));
    }
    
    this.setState({ 
      coursesCount: courses.length,
      courses 
    });
  }

  searchInCourse = (course, searchKeyword) => {
    const stringToSearchIn = `${course.name.toLowerCase()} ${course.description.toLowerCase()} ${course.location.toLowerCase()} ${course.number.toLowerCase()}`;
    return stringToSearchIn.includes(searchKeyword.toLowerCase());
  }

  handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.jumpToTable();
    }
  }  

  handleSortChange = (ev) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('Sort', ev.target.value);
    searchParams.set("Limit", '10');
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
    this.pipeCourses();
  }

  jumpToTable = () => {
    const el = document.getElementById("course-table");
    el.scrollIntoView(); 
  }

  render() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let searchParam = params.get("Suche");
    const sortParam = params.get("Sort");
    const initSort = sortParam ? sortParam : 'Lehrgangsdatum';

    return (
      <div>

        <div className="row mb-medium">
          <div className="col align-center">
            <div className="input-group" id="search-field">
              <input 
                type="search" 
                className="form-control"
                placeholder="Kurse/Fortbildungen nach Begriff/Stichwort durchsuchen…" 
                value={ searchParam } 
                onChange={this.handleSearchChange}
                onKeyUp={ this.handleSearchKeyUp }
              />
              <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={ this.jumpToTable }>
                <div className="input-group-text"><i className="fa navigation-icon fa-search"></i></div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-medium">
          <div className="col align-center">
            <button type="button" className="btn submit-button reset-button" style={{ textTransform: 'none'}} onClick={ this.resetSearch } disabled={ !searchParam }>Suche zurücksetzen</button>
            <a href="#course-table" className="btn submit-button reset-button treffer-button small">{ this.state.coursesCount } Treffer</a>
          </div>
        </div>

        <div style={{ paddingTop: '10px' }}>
            <span>Sortieren nach: </span>
            <select class="form-select" onChange={ this.handleSortChange } value={ initSort }>
                <option value="Lehrgangsdatum">Lehrgangsdatum</option>
                <option value="KursnummerAbsteigend">Kurs-Nummer absteigend</option>
                <option value="KursnummerAufsteigend">Kurs-Nummer aufsteigend</option>
            </select>
        </div>

        <div className="boxed">
          <KurseTable
            courses={ this.state.courses }
            bookmarkedCourses={ [] }
            disableBookmarks={ true }
          />
        </div>
        <div className="row mb-x-large">
          <div className="col">
            <div className="seperator"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPageTargetGroups;